<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              to="/dashboard/marketing"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">GMV marketting</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </router-link>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/admin') }"
      v-if="hasPermission('list-user') || hasPermission('role-permission')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Quản lý hệ thống</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/role-permission"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('role-permission')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Vai trò và quyền truy cập</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/user/list-user"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('list-user')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Quản lý user</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section">
      <h4 class="menu-text">Giao dịch</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/transitions') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Giao dịch</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Giao dịch</span>
            </span>
          </li>

<!--          <router-link-->
<!--            to="/transactions/elastic"-->
<!--            v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--            v-if="hasPermission('elastic')"-->
<!--          >-->
<!--            <li-->
<!--              aria-haspopup="true"-->
<!--              data-menu-toggle="hover"-->
<!--              class="menu-item"-->
<!--              :class="[-->
<!--                isActive && 'menu-item-active',-->
<!--                isExactActive && 'menu-item-active',-->
<!--              ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot">-->
<!--                  <span></span>-->
<!--                </i>-->
<!--                <span class="menu-text">Lịch sử giao dịch V3</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

          <router-link
            to="/transactions/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('listTransactions')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Danh sách</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/transactions/auto-payment/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('auto-payment')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Thanh toán tự động</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/transactions/sync-status"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('sync-status')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Đồng bộ trạng thái</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/transactions/with-voucher"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('with-voucher')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Sử dụng coupon</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <SidebarStats></SidebarStats>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/credit-card') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-cardiogram"></i>
        <span class="menu-text">Thẻ quốc tế</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Thẻ quốc tế</span>
            </span>
          </li>

          <router-link
            to="/credit-card/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('credit-card-list')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Danh sách</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/card-store') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-cardiogram"></i>
        <span class="menu-text">Kho thẻ</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Danh sách thẻ</span>
            </span>
          </li>

          <router-link
              to="/card-store/list"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('cardList')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Danh sách thẻ</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/credit-card') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-users-1"></i>
        <span class="menu-text">Tài khoản</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/account/enterprise"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('enterprise')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tài khoản doanh nghiệp</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/account/merchant"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('merchant')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tài khoản Merchant</span>
              </a>
            </li>
          </router-link>
          <router-link
                  to="/account/black-list"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="hasPermission('blackList')"
          >
            <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Black list</span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/tools/users/block"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('usersBlock')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">D/s tài khoản bị khóa</span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/tools/users/account"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">D/s tài khoản</span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/tools/user/request-lock"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('userRequestLock')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yêu cầu khóa tài khoản</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/transitions') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-settings"></i>
        <span class="menu-text">Công cụ</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Công cụ</span>
            </span>
          </li>

          <router-link
            to="/tools/add-bin-code"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Thêm thẻ phát hành tại VN</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/config-notifications"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('config-notifications')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config notifications</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/config-segment"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('config-segment')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config Segment</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/retry-card"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('retry-card')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Retry cards</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/promotion"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('promotion')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Khuyến mãi</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/service-log"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('serviceLog')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Log ON/OFF dịch vụ</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/tools/fee"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('fee')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config chi phí</span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/tools/schedule"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('schedule')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config hẹn giờ</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/tools/route-app"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('routeApp')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config route app</span>
              </a>
            </li>
          </router-link>

            <router-link
                to="/tools/config-deeplink"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="hasPermission('configDeeplink')"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                            <span></span>
                        </i>
                        <span class="menu-text">Config deeplink</span>
                    </a>
                </li>
            </router-link>

          <router-link
              to="/tools/message-template"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('messageTemplate')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config template message </span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/tools/service-app"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('serviceApp')">

            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Config service app</span>
              </a>
            </li>
          </router-link>
            <router-link
                to="/tools/bounce-mail"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="hasPermission('bounceMail')">

                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                            <span></span>
                        </i>
                        <span class="menu-text">Config bounce mail</span>
                    </a>
                </li>
            </router-link>
            <router-link
                to="/tools/mail-merchant"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="hasPermission('mailMerchant')">

                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                            <span></span>
                        </i>
                        <span class="menu-text">Config mail merchant</span>
                    </a>
                </li>
            </router-link>
            <router-link
                to="/tools/risk/notification"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="hasPermission('riskNotification')"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                            <span></span>
                        </i>
                        <span class="menu-text">Cấu hình cảnh báo rủi ro</span>
                    </a>
                </li>
            </router-link>

          <router-link
              to="/tools/okr"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('okr')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cấu hình OKR</span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/tools/segment/action"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('segmentAction')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Khóa tài khoản theo segment</span>
              </a>
            </li>
          </router-link>

          <router-link
              to="/tools/bank/maintenance"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('bankMaintenance')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cấu hình bảo trì ngân hàng</span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/tools/bank/monitor"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('bankMonitor')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Số dư tài khoản đảm bảo</span>
              </a>
            </li>
          </router-link>
          <router-link
              to="/tools/risk/scoring"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('riskScoring')"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Chấm điểm rủi ro</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <SidebarReconcile></SidebarReconcile>
    <SidebarAutoReconcile></SidebarAutoReconcile>
    <SidebarVoucher></SidebarVoucher>
    <SidebarCampaign></SidebarCampaign>
    <ComboVoucher></ComboVoucher>
    <SidebarRefer></SidebarRefer>
    <SidebarStudentRefer></SidebarStudentRefer>
    <SidebarReport></SidebarReport>
    <SidebarCharity></SidebarCharity>
    <SidebarTopService></SidebarTopService>
    <SidebarStatistics></SidebarStatistics>
    <SidebarMarketing></SidebarMarketing>
  </ul>
</template>

<script>
import SidebarVoucher from "@/view/components/sidebars/SidebarVoucher";
import SidebarReconcile from "@/view/components/sidebars/SidebarReconcile";
import SidebarAutoReconcile from "@/view/components/sidebars/SidebarAutoReconcile";
import SidebarStats from "@/view/components/sidebars/SidebarStats";
import SidebarCampaign from "@/view/components/sidebars/SidebarCampaign";
import ComboVoucher from "@/view/components/sidebars/SidebarComboVoucher";
import SidebarRefer from "@/view/components/sidebars/SidebarRefer";
import SidebarReport from "@/view/components/sidebars/SidebarReport";
import SidebarStatistics from "@/view/components/sidebars/SidebarStatistics";
import Common from "@/core/mixins/common";
import SidebarStudentRefer from "@/view/components/sidebars/SidebarStudentRefer";
import SidebarCharity from "@/view/components/sidebars/SidebarCharity.vue";
import SidebarMarketing from "@/view/components/sidebars/SidebarMarketing.vue";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import SidebarTopService from "@/view/components/sidebars/SidebarTopService.vue";

export default {
  name: "KTMenu",
  mixins: [Common],
  components: {
    SidebarTopService,
    SidebarVoucher,
    SidebarReconcile,
    SidebarAutoReconcile,
    SidebarCampaign,
    SidebarStats,
    ComboVoucher,
    SidebarRefer,
    SidebarReport,
    SidebarStudentRefer,
    SidebarCharity,
    SidebarStatistics,
    SidebarMarketing
  },
  methods: {
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  },
};
</script>
